/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
html {
  font-family: Arial, Georgia, serif;
  font-size: 16px;
  line-height: 1.5rem;
}

body {
  color: #222;
  background-color: #fcfcfc;
}

h1 { font-size: 2.488em; }
h2 { font-size: 1.5em; }

h1:not(:first-of-type), h2 {
  margin: 1em 0 0.5em;
}

h1, h2 {
  color: #2f586b;
}

p {
  margin: 0.25em 0 0;
}

input, select {
  border-color: rgb(143, 143, 143);
  font-size: 1.2rem;
  padding: 0.25em 0.5em;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  min-width: 30%;
  max-width: max(30%, 400px);
  flex: 1;
  text-align: left;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 1em;
  box-sizing: border-box;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label {
  font-weight: bold;
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

button {
  background-color: #2f586b;
  font-size: 1.2rem;
  padding: 0.5em 1em;
  margin: 0;
  border-radius: 8px;
  border-style: none;
  color: #FFF;
  cursor: pointer;
  font-weight: 500;
  transition: color 100ms;
  display: block;

  &:hover, &:focus {
    background-color: #618697;
  }

  &:disabled {
    background-color: #959595;
    cursor: default;
  }
}
:root{
  --example: green;
  --labelsColor: white;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
